import React, { useState, useEffect } from "react";
import PrincipalContainer from "../PrincipalContainer";
import Footer from "../Footer";
import HeaderTemplate from "../HeaderTemplate/HeaderTemplate"
import MainContent from "../MainContent";
import TitleHeader from "../TitleHeader";
import { useMachineContext } from "../../Context/contextMachine";
import logoHeader from "../../Images/headerIconBancolombia.svg";
import { AlertGoOut } from "../AlertGoOut/AlertGoOut"
import ContainerHeader from "../ContainerHeader";
import Container from "../Container";
import OtpInputDynamicKey from "../OtpInputDynamicKey";
import ButtonGroupToken from "../CustomButtons/ButtonGroupTokenForm/ButtonGroupToken"
import { ErrorFront } from "../../Styles/TokenFormStyle/ErrorFront"
import { FormGroup } from "../../Styles/PasswordFormStyle/FormGroup"
import { HandlerTokenForm } from "../../HandlerComponents/HandlerTokenForm/HandlerTokenForm"
import AlertMessage from "../AlertMessage";
import { getValueCookie } from "../../Utilities/httpUtils";
import { UseFua } from "../../Context/index";
import { back } from "../../Utilities/GeneralFunctions"

export function TokenForm() {
  const [machine, send] = useMachineContext();
  const [showAlert, setShowAlert] = useState(true);
  const [values, setvalues] = useState();
  const [error, setError] = useState("");
  const [activeButton, setActiveButton] = useState(false);
  const [incorrectDynamicKey, setIncorrectDynamicKey] = useState(false)
  const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
  const [isV2, setIsV2] = useState(false);
  const errorIncorrectDynamicKey = "validateAlphanumericKeyAndTokenSf.validateAuthTokenSf.errorIncorrectDynamicKey"
  const { deviceType, setDevice } = UseFua();

  useEffect(async () => {
    setIsV2(await getValueCookie("redirectToSvnIncludeV2"))
    setDevice()
  }, []);

  useEffect(async () => {
    if (machine.matches(errorIncorrectDynamicKey)) {
      setShowAlert(true);
    }
  }, [machine]);

  const validateForm = async () => {
    send("RETRY");
    send("VALIDATEAUTHTOKENSF", { otp: values, deviceType });
  };

  const showPopUp = () => {
    setShowPopUpGoOut(true)
  };

  const close = () => {
    setShowAlert(false);
  };

  return (
    <PrincipalContainer>
      <HeaderTemplate logoHeader={logoHeader} isV2={isV2} exitFunction={showPopUp} backFunction={back} marginBottom='45px' marginBottomMobile='45px' />
      {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={false} isV2={isV2} />}

      <MainContent>
        {(machine.matches(errorIncorrectDynamicKey)) && showAlert && (
          <AlertMessage close={close} title="Clave dinámica incorrecta " message="Verifica e intenta nuevamente." />
        )}
        <TitleHeader title="Ingresa el código de seguridad" widthTitle="454px" heightTitle="30px" topTittle="160px" />
        <Container height="232px" top="30%">
          <ContainerHeader
            icon
            heightL="10%"
            subTitle={"Ingresa el código generado en la app o en el token."}
          />
          <FormGroup id="formGroup">
            <OtpInputDynamicKey
              setvalues={setvalues}
              error={error}
              setError={setError}
              setActiveButton={setActiveButton}
              setIncorrectDynamicKey={setIncorrectDynamicKey}
              incorrectDynamicKey={incorrectDynamicKey}
              message={"Debe tener mínimo 6 caracteres"}
            />
            {error && <ErrorFront error={error}>{error}</ErrorFront>}
            <ButtonGroupToken machine={machine} validateForm={validateForm} isActiveButton={activeButton} />
          </FormGroup>
          <HandlerTokenForm machine={machine}></HandlerTokenForm>
        </Container>
      </MainContent>
      <Footer top="0" topMovil="560px" />
    </PrincipalContainer>
  );
}
