import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { UseFua } from "../../Context/index";
import Container from "../Container";
import ContainerHeader from "../ContainerHeader";
import MainContent from "../MainContent";
import PrincipalContainer from "../PrincipalContainer";
import { useMachineContext } from "../../Context/contextMachine";
import { setModulus } from "../../Services/bcOaep";
import Input from "../Input";
import Footer from "../Footer";
import Loader from "../Loader";
import { getTrace, getValueCookie } from "../../Utilities/httpUtils";
import {
    EFA002,
    timeOutCustomerFlow
} from "../../Constant/Constants";
import AlertMessage from "../AlertMessage";
import TitleHeader from "../TitleHeader";
import { ErrorFront } from "../../Styles/AlphanumericScreen/ErrorFront"
import { FormGroup } from "../../Styles/UserFormStyle/FormGroup"
import iconLock from "../../Images/lock.svg";
import { ContainerRecaptcha } from "../../Styles/AlphanumericScreen/ContainerRecaptcha"
import config from "../../config";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import logoHeader from "../../Images/headerIconBancolombia.svg";
import { AlertGoOut } from "../AlertGoOut/AlertGoOut"
import HeaderTemplate from "../HeaderTemplate/HeaderTemplate"
import { ButtonGroupAlphanumericScreen } from "../CustomButtons/ButtonGroupAlphanumericScreen/ButtonGroupAlphanumericScreen"
import { HandlerAlphanumericScreen } from "../../HandlerComponents/HandlerAlphanumericScreen/HandlerAlphanumericScreen"
import { validationSchemaAlphanumeric } from "../../Utilities/Regex"
import { back } from "../../Utilities/GeneralFunctions"
import { useInterval } from 'react-use';

export function AlphanumericScreen() {
    const history = useHistory();
    const { setDevice, username } = UseFua();
    const [machine, send] = useMachineContext();
    const [showAlert, setShowAlert] = useState(true);
    const isMatchLoader = ["successSSO", "redirect", "validateUrl", "validateParameters"]
    const isRecaptchaActive = config.recaptcha.RECAPTCHA_ALPHANUMERIC_KEY_STATUS;
    const recaptchaRef = useRef();
    const [captchaToken, setCaptchaToken] = useState(null);
    const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
    const [isButtonBack, setIsButtonBack] = useState(false);
    const [isV2, setIsV2] = useState(false);
    const [password, setPassword] = useState("");

    useEffect(async () => {
        if (isRecaptchaActive === "false") {
            setCaptchaToken("")
        } else if (!machine.context.recapvisible) {
            getRecaptchaToken()
        }
        setIsV2(await getValueCookie("redirectToSvnIncludeV2"))
        setModulus(machine.context.modulus);
        setDevice();
    }, []);

    useInterval(() => {
        window.location.replace(machine.context.redirectUri + EFA002 + getTrace(machine.context));
    }, timeOutCustomerFlow);

    const close = () => {
        setShowAlert(false);
    };

    const handledSubmit = value => setCaptchaToken(value)

    const showPopUp = () => {
        setShowPopUpGoOut(true)
        setIsButtonBack(false)
    };

    const getRecaptchaToken = async () => {
        const token = await recaptchaRef.current.executeAsync();
        handledSubmit(token)
    };

    return (
        <PrincipalContainer>
            <HeaderTemplate logoHeader={logoHeader} isV2={isV2} exitFunction={showPopUp} backFunction={back} marginBottom='45px' marginBottomMobile='45px' />
            {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={isButtonBack} isV2={isV2} />}
            <MainContent>
                <TitleHeader title="Clave Alfanúmerica" widthTitle="454px" heightTitle="30px" topTittle="128px" />
                {(isMatchLoader.some(machine.matches)) && (<Loader />)}
                {machine.matches("sendCredentials.error") && showAlert && (
                    <AlertMessage close={close} title="Datos incorrectos" message="El usuario o la contraseña no son correctos. Intenta de nuevo" />
                )}
                <Container>
                    <ContainerHeader
                        subTitle="La clave es la misma con el que ingresas a la"
                        otherDescription={"Sucursal Virtual Negocios."}
                        widthTitle="460px"
                        icon={false}
                    />
                    <Formik
                        initialValues={{
                            pass: "",
                        }}
                        validationSchema={validationSchemaAlphanumeric}
                        onSubmit={values => {
                            setPassword(values.pass)
                            send("VALIDATEALPHANUMERICKEY", { username, password: values.pass, captchaToken });
                            if (isRecaptchaActive === "true") {
                                recaptchaRef.current.reset();
                                setCaptchaToken(null)
                            }
                        }}
                    >
                        {({ errors, touched, isValid, values, handleSubmit }) => (
                            <>
                                <FormGroup onSubmit={handleSubmit} >
                                    <Input
                                        type="password"
                                        name="pass"
                                        autoCorrect="off"
                                        autoComplete="off"
                                        placeholder="&nbsp;"
                                        label="Clave"
                                        valid={touched.pass && !errors.pass}
                                        error={touched.pass && errors.pass}
                                        top="0px"
                                        icon={iconLock}
                                        value={values.pass}
                                    />
                                    {errors.pass && <ErrorFront>{errors.pass}</ErrorFront>}

                                    {isRecaptchaActive === "true" && (
                                        <ContainerRecaptcha>
                                            {machine.context.recapvisible && (
                                                <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    sitekey={config.recaptcha.RECAPTCHA_KEY}
                                                    onChange={handledSubmit}
                                                />
                                            )}
                                            {!machine.context.recapvisible && (
                                                <ReCAPTCHA
                                                    size="invisible"
                                                    ref={recaptchaRef}
                                                    sitekey={config.recaptcha.RECAPTCHA_KEY_INVISIBLE}
                                                />
                                            )}
                                        </ContainerRecaptcha>
                                    )}
                                    <ButtonGroupAlphanumericScreen errors={errors} isValid={isValid} captchaToken={captchaToken} />
                                </FormGroup>
                            </>
                        )}
                    </Formik>
                    <HandlerAlphanumericScreen machine={machine} history={history} />
                </Container>
            </MainContent>
            <Footer top="0" topMovil="560px" />
        </PrincipalContainer>
    );
}
